import React from 'react';
import Layout from '../components/Layout/Layout';
import HeaderPage from '../components/HeaderPage/HeaderPage';
import withIntl from '../i18n/withIntl';
import Clients from '../components/Clients/Clients';

const header = {
  title: '404Title',
  subTitle: '404SubTitle',
};

const NotFoundPage = (props) => (
  <div>
    <Layout page={'404'} {...props}>
      <HeaderPage data={header}/>
      <Clients background={'#fff'}/>
    </Layout>
  </div>
);

export default withIntl(NotFoundPage);
